import React, { Fragment } from 'react'

import Card from './Card'
import IntroCard from './IntroCard'
import './AppContent.css'

import jessChatScreenshot01 from '../images/chat_04.png'
import jessChatScreenshot02 from '../images/chat_05.jpg'
import pawsGoScreenshot01 from '../images/pawsGo_001.png'
import pawsGoScreenshot02 from '../images/pawsGo_002.png'
import forYouMessagesScreenshot01 from '../images/bb_app_01.png'
import forYouMessagesScreenshot02 from '../images/forYou_7tablet_01.png'
import walkingDogScreenshot01 from '../images/bb_app_03.jpeg'
import walkingDogScreenshot02 from '../images/bb_app_08.jpeg'
import bleScreenshot01 from '../images/bb_app_06.jpg'
import bleScreenshot02 from '../images/bb_app_07.jpg'
import peerConnectScreenshot01 from '../images/peerConnect_01.png'
import peerConnectScreenshot02 from '../images/peerConnect_02.png'
import emotionsScreenshot01 from '../images/bb_app_10.png'
import emotionsScreenshot02 from '../images/bb_app_11.png'
import pdfScreenshot01 from '../images/bb_app_09.png'
import pdfScreenshot02 from '../images/pdf_02.png'

const appScreenshots = [[jessChatScreenshot01, jessChatScreenshot02],
                        [pawsGoScreenshot01, pawsGoScreenshot02],
                        [forYouMessagesScreenshot02, forYouMessagesScreenshot01],
                        [walkingDogScreenshot01, walkingDogScreenshot02],
                        [bleScreenshot02, bleScreenshot01],
                        [peerConnectScreenshot01, peerConnectScreenshot02],
                        [emotionsScreenshot01, emotionsScreenshot02],
                        [pdfScreenshot02, pdfScreenshot01]]



const AppContent = (props) => {
    const app = props.theApp

    return ( 
        <div className='app-container'>
            <div className='app-title-container'>
                <div className='app-title'>
                    {app.name}
                </div>
            </div>
            <div className='app-content'>
                
                    <img className='app-screenshot-1'
                        src={appScreenshots[app.id-1][0]} alt='App screenshot 1' />
                        <div className='app-intro-part'>
                            <IntroCard theApp={app} />    
                        </div>
                        <div className='app-descript-part'>
                            <Card theApp={app}/>
                        </div>
                    <img className='app-screenshot-2'
                        src={appScreenshots[app.id-1][1]} alt='App screenshot 2' />
            </div>
        </div>
    )
}

export default AppContent