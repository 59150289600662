import React, { useState, useEffect } from 'react'

import './DropDownList.css'

const DropDownList = ({ options, selectedAppCallback }) => {

    const [showMenu, setShowMenu] = useState(false)
    const [selectedApp, setSelectedApp] = useState(null)

    useEffect(() => {
        const handler = () => setShowMenu(false)

        window.addEventListener("click", handler)
        return () => {
            window.removeEventListener("click", handler)
        }
    })


    const handleInputClick = (event) => {
        event.stopPropagation()
        setShowMenu(!showMenu)
    }

    const getDisplay = () => {
        if (selectedApp) {
            return selectedApp.label
        } else {
            return "Select an app..."
        }
    }

    const onAppClicked = (app) => {
        selectedAppCallback(app.label)
        setSelectedApp(app)
    }

    const isSelected = (app) => {
        if (!selectedApp) {
            return false
        }
        return selectedApp.value === app.value
    }

    return (
        <div className="dropdown-container">
            <div onClick={handleInputClick} className="dropdown-input">
                <div className="dropdown-selected-value">{getDisplay()}</div>
                <div className="dropdown-tools">
                <div className="dropdown-tool">
                    **
                </div>
                </div>
                
            </div>
            {showMenu && (
            <div className='dropdown-menu'>
                    {options.map((option) => (
                        <div 
                            onClick={() => onAppClicked(option)}
                            key={option.value} 
                            className={`dropdown-item ${isSelected(option) && "selected"}`}>
                            {option.label}
                        </div>        
                    ))}
                </div>)}
        </div>
    )
}

export default DropDownList