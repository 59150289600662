

export const androidApps = [
  {name: "Jess Chat Bot",
    abb: "jessChat", 
    id: 1, 
    description: "Jess Chat Bot lets users talk to a chatbot.", 
    detailDescription: ["Jess Chat Bot provides an interface for the user to chat with a chatbot hosted in AWS.  I made the chatbot with Amazon Lex, by setting the utterences to trigger intents to present a response.",
                        "Users need to register for an account and login before they can send message to the chatbot.  I use Amazon Cognito to create and authenticate user accounts.",
                        "The app stores the chats in the room database and display to users in the records page.  The user can also change and reset password.",
                      ],
    releaseDate: "Mar 23, 2023",
    privacyPolicy: 'https://www.jessbitcom.pro/jessChatPolicy',
    privacyIntro: "Pui Ling Hon built the For You Messages app as a free app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "",
    privacyLocation: "None",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "https://github.com/morningbob/JessChatLex2"
  },
  {name: "Paws Go",
    abb: "pawsGo", 
    id: 2, 
    description: "A platform for pet owners to report lost pets.", 
    detailDescription: ["Paws Go is an app for pet owners to report their lost pets.  It is a platform for users who found a pet and who lost a pet, to communicate.  The users can also message the dog owners in the app.",
                        "The app uses Firebase Authentication to validate users.  Users need to create an account using an email and a password.  All the functions of the app requires users to login the app.",
                        "The app also use Google Maps to let users to mark the lost place for the pet.  It also uses the map to display the lost location to the other users.  However, the pet owners can also write in words for the lost location.",
                      ],
    releaseDate: "Jan 18, 2023",
    privacyPolicy: 'None',
    privacyIntro: "Pui Ling Hon built the For You Messages app as a free app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "",
    privacyLocation: "None",
    iconCredits: "",
    platform: "Both Android and iOS",
    language: "Kotlin, SwiftUI",
    repo: "https://github.com/morningbob/pawsGo_android"
  },
  {name: "For You Messages",
    abb: "foryou", 
    id: 3, 
    description: "Asymmetric Encryption for messages.", 
    detailDescription: ["For You Messages is an app which uses asymmetric encryption for sending messages.  ",  
                      "The app creates a public key and a private key for the user.  The public key will be stored in the server. ",
                      "Users can encrypt messages using the target user's public key.  The targer user can then decrypt the message by his private key.",
                      "Users can also schedule when to send the messages, and/or add a password to the message."
                    ],
    releaseDate: "October 2022",
    privacyPolicy: 'https://for-you-messages.flycricket.io/privacy.html',
    privacyIntro: "Pui Ling Hon built the For You Messages app as a free app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "2022-10-13",
    privacyLocation: "None",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "To be posted"
  },
  {name: "Walking Dogs",
    abb: "dogwalk", 
    id: 4, 
    description: "Sharing dog walks routes between users.", 
    detailDescription: ["Walking dogs is an app for dog owners to share their favorite dog walk routes to the other dog owners. The users can also get suggested routes by city, which are shared by the other users.",  
                      "The app requires email and password authentication.  This helps the backend  to save the information of the route and suggest to the other users.",
                    "Users can also request a password reset email to reset the password."],
    releaseDate: "July 2022",
    privacyPolicy: 'https://pages.flycricket.io/chat-in-ble/privacy.html',
    privacyIntro: "JessBitCom built the Walking Dog app as a free app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "2022-07-03",
    privacyLocation: "The app needs the access coarse location permission and access fine location permission in order to let the user locate where they are, in the map.  The location information won’t be sent to a server or the devices scanned.  The app won’t even store the information in the device.  ",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "https://github.com/morningbob/shareRoutes_android"
  },
  {name: "Chat in BLE",
    abb: "chat", 
    id: 5, 
    description: "Messaging in Bluetooth Low Energy.", 
    detailDescription: ["It is a chat app using Bluetooth Low Energy.  The user can chat with nearby devices using Bluetooth Low Energy.  But both devices need to have the app installed.  The app tries to verify the messages sent the best it can.",  
                      "The chat is one to one at any time.  The app saves the chats in the device.  The user can select a device and the messages with that device will be shown.  ",
                    "Users can send text messages, as well as files."],

    releaseDate: "January 2022",
    privacyPolicy: 'https://pages.flycricket.io/chat-in-ble/privacy.html',
    privacyIntro: "JessBitCom built the Chat in BLE app as an Ad Supported app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "2022-01-10",
    privacyLocation: "The app needs the access coarse location permission and access fine location permission in order to do Bluetooth Low Energy scanning.  The location information won’t be sent to a server or the devices scanned.  The app won’t even store the information in the device.  It is just because the Bluetooth Low Energy scanning API needs the permissions.  The scanning of the nearby devices is needed because it allows the user’s device to discover the devices nearby, to discover what devices it can connect to and chat to.  It is also needed for the user’s device to be discoverable for other devices to connect to and chat to.",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "https://github.com/morningbob/bleChat_android.git",
  },
  {name: "Peer Connect",
    abb: "foryou", 
    id: 6, 
    description: "Chat app using bluetooth and WiFi as media", 
    detailDescription: ["This app uses bluetooth and wifi to connect and chat with nearby devices.  The connection is peer to peer.",  
                    "The app uses the MultiPeer Connectivity module from Apple.",
                    "The device of the user who initiates the chat serves as the server of the chat.  All the messages sent by the members of the chat is directed to this device.  Then, the device sends the message to all the other users in the chat.",
                    "The chat can have up to 8 members."],
    releaseDate: "2021",
    privacyPolicy: 'None',
    privacyIntro: "",
    privacyDate: "",
    privacyLocation: "",
    iconCredits: "",
    platform: "iOS",
    language: "SwiftUI",
    repo: "https://github.com/morningbob/peerConnect_iOS_swiftUI.git",
  },
    {name: "Emotions AI", 
    abb: "emotions",
    id: 7,
    description: "AI model to classify sentences to dfferent emotions.  ", 
    detailDescription: ["This app applies machine learning model, the Recurrent Neural Network to classify English sentences to 12 different classes.  The classes include different emotions, and different classifications of the sentences.  ", 
                      "The app gets a sentence from the user.  Then, it launches the machine learning model that stored in the app.  The model takes the input and output a prediction, which is the emotion predicted or the classification of the sentence.  ",
                      "The model was trained on around 5000 short English sentences.  It can be treated as a test on human emotions using small scale machine learning models."],
    releaseDate: "2020",
    privacyPolicy: 'https://pages.flycricket.io/emotions-ai/privacy.html',
    privacyIntro: "JessBitCom built the Emotions AI app as a Commercial app. This SERVICE is provided by JessBitCom and is intended for use as is.",
    privacyDate: "2022-01-25",  
    privacyLocation: "None",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "To be posted"
  },
    {name: "To PDF",
    abb: "pdf", 
    id: 8, 
    description: "Turn paper documents to pdf.", 
    detailDescription: ["The app is a tool for users to take photos of paper documents or handwritten notes and create pdf documents.",
                      "In the app, the user need to create a job before one can take photos.  The photos will be stored according to the job on the device.",
                      "Users are able to view the photos in the job before turning it to a pdf document.  Users can also rearrange the order of the photos.  These are the pages of the pdf.",
                      ],
    releaseDate: "October 2021",
    privacyPolicy: 'https://pages.flycricket.io/to-pdf/privacy.html',
    privacyIntro: "JessBitcom built the To PDF app as an Ad Supported app. This SERVICE is provided by JessBitCom at no cost and is intended for use as is.",
    privacyDate: "2021-10-10", 
    privacyLocation: "None",
    iconCredits: "",
    platform: "Android",
    language: "Kotlin",
    repo: "https://github.com/morningbob/to_pdf_android.git",
    },
  ]