import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import './HomePage.css'
import jessChatScreenshot01 from '../images/chat_04.png'
import forYouAppScreenshot01 from '../images/bb_app_01.png'
import bleScreenshot01 from '../images/bb_app_06.jpg'
import jessieHon01 from '../images/bb_profile_01.png'
import walkingDogScreenshot01 from '../images/bb_app_03.jpeg'
import pdfScreenshot01 from '../images/bb_app_09.png'
import py01Screenshot from '../images/bb_py_01.png'
import bleScreenshot02 from '../images/bb_app_07.jpg'
import walkingDogScreenshot02 from '../images/bb_app_08.jpeg'
import jessieHon02 from '../images/bb_profile_02.png'
import emotionsScreenshot01 from '../images/bb_app_10.png'
import walkingDogScreenshot03 from '../images/bb_app_12.jpeg'

import Carousel, { CarouselItem } from '../components/Carousel'
import useWindowDimensions from '../helpers/useWindowDimensions'


const HomePage = () => {
    //const width = window.innerWidth
    const { height, width } = useWindowDimensions()
    let desiredWidth = 0
    if (width >= 600) {
        desiredWidth = "25"
    } else if (width < 600) {
        desiredWidth = "100"
    }
    return ( 
        <Fragment>
            <div dangerouslySetInnerHTML={{ __html: `
                <df-messenger
                    intent="WELCOME"
                    chat-title="JessChatBot"
                    agent-id="7bc479ed-ea09-448c-bcae-f64c925be2a3"
                    language-code="en"
                    ></df-messenger>
`           }} />
            <Helmet>
                <meta charSet="utf-8" />
                <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
            </Helmet>
            <div className='carousel-banner'>
                <Carousel showWidth={desiredWidth}>
                    <CarouselItem><img className='home-photo-forYou'
                        src={forYouAppScreenshot01} alt="For You Messages app's screenshot"></img></CarouselItem>
                    <CarouselItem><img className='home-photo-jessChat'
                        src={jessChatScreenshot01} alt="Jess Chat Bot app's screenshot"></img></CarouselItem>
                    <CarouselItem><img className='home-photo-ble'
                        src={bleScreenshot01} alt="For You Messages app's screenshot"></img></CarouselItem>
                    <CarouselItem><img className='home-photo-jessie'
                        src={jessieHon01} alt="Jessie Hon and her dog Python photo" /></CarouselItem>
                    <CarouselItem><img className='home-photo-walking'
                        src={walkingDogScreenshot01} alt="Walking Dog app's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={pdfScreenshot01} alt="To PDF app's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={py01Screenshot} alt="Jessie's dog Python's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={walkingDogScreenshot02} alt="Walking Dog app's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={bleScreenshot02} alt="BLE app's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={jessieHon02} alt="Jessie and Python's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={emotionsScreenshot01} alt="BLE app's screenshot" /></CarouselItem>
                    <CarouselItem><img className='home-photo-pdf'
                        src={walkingDogScreenshot03} alt="Jessie and Python's screenshot" /></CarouselItem>
                </Carousel>
            </div>
        </Fragment>

    )
   
}

export default HomePage