import React, { Fragment } from 'react';

import ContactForm from '../components/ContactForm'
import './ContactPage.css'


const ContactPage = () => {
    return (
        <Fragment>
            <div className='contact-page'>Contact</div>
            <ContactForm />
            <div className='contact-email-title'>
                    Contact emails:  
                </div>
                <div className='contact-email'>
                    admin@jessbitcom.pro <br/>
                    jessie.apps.store@gmail.com
                </div>
                
        </Fragment>
    )
}

export default ContactPage