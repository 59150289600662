import React, { Fragment } from 'react'

import './ProfilePage.css'
import Carousel, { CarouselItem }  from '../components/Carousel'
import forYouMessagesScreenshot from '../images/bb_app_01.png'
import walkingDogScreenshot from '../images/bb_app_03.jpeg'

const ProfilePage = () => {
    return ( 
        <Fragment>
            <div className='profile-content'>
                <div className='profile-content-pic01'>
                    <img className='profile-images' src={forYouMessagesScreenshot} alt='For You Messages screenshot' />
                </div>
                <div className='profile-content-main'>
                    <div className='profile-content-name'>
                        Jessie Hon 
                    </div>
                    Born in Hong Kong <br/><br/>
                    Graduated from University of Toronto, Scarborough Campus <br/><br/>
                    Got Certified General Accountant Designation in 2009, 
                    which CGA later combined with the other Accountant
                    designations associations to form Chartered Professional Accountants Association<br/><br/>
                    Self taught programmer in Android and iOS mobile applications <br/><br/>
                    Released several android mobile apps in recent years <br/><br/>
                    Really like dogs.  Had a Shih tzu for 13 years.  Now, she has a Shiba Inu <br/><br/>
                    Like reading, psychology, really keen in learning new programming tools<br/><br/>
                    
                </div>
                <div className='profile-content-pic02'>
                    <img className='profile-images' src={walkingDogScreenshot} alt='Walking Dog screenshot' />
                </div>
            </div>
        </Fragment>

    )
}

export default ProfilePage