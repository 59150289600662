import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import './Footer.css'
import IGicon from '../images/Instagram_icon.png'
import contacts_icon from '../images/email.png'

const Footer = () => {
    return ( 
        <Fragment>
            <div className='footer'>
                <div className='footer-name'>
                    By Jessie Hon Oct 14, 2022
                </div>
                <a  href='https://www.instagram.com/bobbyhon1668'>
                    <img className='IG' 
                        src={IGicon} alt='Instagram icon' 
                    />
                </a>
                <Link className='contact_link' to='/contact'>
                    <img className='contacts'
                        src={contacts_icon} alt='contacts icon' />
                </Link>
            </div>
        </Fragment>

    )
}

export default Footer