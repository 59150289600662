import { Fragment } from "react"

import './Card.css'

const Card = (props) => {
    const app = props.theApp

    // if the repo is "To be posted", we don't use a tag, we use a div

    const processRepo = () => {
        if (app.repo == "To be posted") {
            return (<div className='content-repo content'>{app.repo}</div>)
        } else {
            return (<a className='content-repo content'
                href={app.repo}>{app.repo}</a>)
        }
    }

    const processPolicy = () => {
        if (app.privacyPolicy == "None") {
            return (<div className='content-policy content'>{app.privacyPolicy}</div>)
        } else {
            return (<a className='content-policy content'
                href={app.privacyPolicy}>{app.privacyPolicy}</a>)
        }
    }

    return ( 
        <Fragment>
            <div className='app-descriptions'>
                    
                    <div className='title-description title'>Description:</div>
                    <div className='content-description content'>{app.description}</div>
                    
                    <div className='title-platform title'>Platform:  </div>
                    <div className='content-platform content'>{app.platform}</div>    
                    
                    <div className='title-language title'>Language:  </div>
                    <div className='content-language content'>{app.language}</div>
                    
                    <div className='title-repo title'>Repo:</div>
                    
                    {processRepo()}
                    
                    <div className='title-date title'>Release Date:</div>
                    <div className='content-date content'>{app.releaseDate}</div>
                    
                    <div className='title-policy title'>Privacy Policy:</div>
                    {processPolicy()}
                </div>
        </Fragment>
    )
}

export default Card