import React, { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'

import './Carousel.css'

export const CarouselItem = ({ children, width }) => {
    return (
        <div className='carousel-item' style={{ width: width }}>
            {children}
        </div>
    )
};

const Carousel = ({ children, showWidth } ) => {
    // activeIndex is the index of various pictures
    const [activeIndex, setActiveIndex] = useState(0)

    // this is used to stop the carousel when user hover is detected
    const [paused, setPaused] = useState(false)

    // method to be triggered to update active index
    const updateIndex = (newIndex) => {
        if (newIndex < 0) {
            //newIndex = 0
            newIndex = React.Children.count(children) - 1
        } else if (newIndex >= React.Children.count(children)) {
            //newIndex = React.Children.count(children) - 1
            newIndex = 0
        }

        setActiveIndex(newIndex)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (!paused) {
                updateIndex(activeIndex + 1)
            }
        }, 3000);

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    })

    const handlers = useSwipeable({
        onSwipedLeft: () => updateIndex(activeIndex + 1), 
        onSwipedRight: () => updateIndex(activeIndex -1)
    })

    return (
        <div 
            {...handlers}
            className='carousel'
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}>
            <div className='carousel-inner'
                    style={{ transform: `translateX(-${activeIndex * showWidth}%)` }}>
                {React.Children.map(children, (child, index) => {
                    return React.cloneElement(child, { width: showWidth })
                })}
            </div>
            <div className='indicators'>
                <button
                    onClick={ () => {
                    updateIndex(activeIndex - 1)
                }} >Prev</button>

                {React.Children.map(children, (child, index) => {
                    return (
                        <button
                            className={`${index === activeIndex ? "active" : ""}`}
                            onClick={() => {
                                updateIndex(index)
                            }}
                            >{index + 1}
                        </button>
                    )
                })}
                <button
                    onClick={ () => {
                    updateIndex(activeIndex + 1)
                }} >Next</button>
            </div>
        </div>
        
    )
}

export default Carousel

