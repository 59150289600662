import { Fragment } from "react"

import './IntroCard.css'

const IntroCard = (props) => {
    const app = props.theApp

    const moreContent = app.detailDescription.map((detail) => {
        return (<Fragment>
            <div>{detail}</div>
            <br/>
        </Fragment>)
    })


    return ( 
        <Fragment>
            <div className='app-intro'>
                <div className='descriptions'>
                    {moreContent}
                </div>
            
            </div>  
        </Fragment>
    )
}

export default IntroCard