import React, { useState } from 'react'
import Select from 'react-select'


import './DeleteAccountForm.css'
import DropDownList from './DropDownList'

const DeleteAccountForm = () => {

    const [enteredEmail, setEnteredEmail] = useState('')
    const [selectedApp, setSelectedApp] = useState(null)
    const [selectedDelete, setSelectedDelete] = useState(null)

    const deleteAccountURL = "https://v7eix6ppqc.execute-api.us-east-1.amazonaws.com/live"

    const dropdownColorStyles = {
        control: styles => ({ ...styles, backgroundColor: "rgba(0,0,0,0)"} )
    }

    const appOptions = [ 
        { value: "barter", label: "Barter" },
        { value: "jessChat", label: "Jess Chat Bot" },
        { value: "messages", label: "For You Messages" },
        { value: "walkingDogs", label: "Walking Dogs" },
    ]

    const deleteOptions = [
        { value: "data", label: "My data only" },
        { value: "account", label: "My account and data" }
    ]

    const selectedAppCallback = (app) => {
        setSelectedApp(app)
        console.log(`app ${app} selected passed into parent`)
    } 

    const onDeleteOptionChanged = (event) => {
        console.log(`setting selected delete ${event}`)
        setSelectedDelete(event)
    }

    const onFormSubmitHandler = (event) => {
        event.preventDefault()

        console.log(`selectedDelete: ${selectedDelete}`)
        
        const data = {
            "email": enteredEmail,
            "app": selectedApp,
            "delete": selectedDelete.label
        }
        sendToAWS(data)
        // send http request to AWS API
    }

    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value)
    }

    async function sendToAWS(data) {
        try {
            const response = await fetch(deleteAccountURL, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                console.log(response)
                //throw new Error("something wrong with post request")
            }
            const result = await response.json();
            if (result["success"] === "true") {
                alert("The request was successfully submitted.  Thanks!")
            } 
            console.log(result)
            setEnteredEmail('')
        } catch (error) {
            console.log(error.message)
        } 
    }

    return (
        <div>
            <div className='content'>Please choose the app: </div>
            <div className='drop-down'>
                <DropDownList placeholder="Select..." options={appOptions} selectedAppCallback={selectedAppCallback} />
            </div>
            <div className='content'>Please choose if you want to delete your data or you account: </div>
            <div className='drop-down'>
            <Select 
                value={selectedDelete}
                onChange={onDeleteOptionChanged}
                placeholder="Select..."
                options={deleteOptions}
                styles={dropdownColorStyles}>
                    
                </Select>
                </div>
            <form onSubmit={onFormSubmitHandler}>
                <div className='delete-form-container'>
                        <label className='delete-form-label-email'>Your email:</label>
                        <input className='delete-form-inputBox-email' type='text' 
                            onChange={emailChangeHandler} value={enteredEmail}/>
                        
                        <button className='delete-form-submit-button' type='submit'>Submit</button>
                </div>
            </form>
        </div>
    )
}

export default DeleteAccountForm

/*
{deleteOptions.map((option) => (
                        <option 
                            key={option.value} 
                            value={option.label}>
                                {option.label}
                        </option>
                    ))}

                    label="Select..."
*/