import React, { Fragment } from 'react'



import './AppsPage.css'
import { androidApps } from '../data/AppData'
import AppContent from '../components/AppContent'

//const app = androidApps[0]

const allApps = androidApps.map((app) => {
    return (<Fragment>
        <div><AppContent className='individual-app' theApp={app}/></div>
        <br/>
    </Fragment>)
})

const AppsPage = () => {
    return ( 
        <Fragment>
            
            <div className='introduction' >
                Jessie built and published several apps in Google Play Store.  
                Different apps have different functions.  
                One of the app uses the bluetooth of the device to send messages to nearby devices.<br/><br/>
                Another app uses asymmetric encryption to encrypt messages.  Users can also add a password to the message and send the message on schedule.<br/><br/>
                She also embedded Google Maps into one of her app, to let the users to draw routes on the map and share it.<br/><br/>
                The Walking Dog app and For You Messages app uses Firebase Authentication to login users.
                They also uses Google's Firestore or Firebase realtime database to store users' data.<br/><br/>
                Jessie also created and trained a deep learning model to identify different emotions.  
                She deployed the model locally, in the devices.  
            </div>
            
            {allApps}

        </Fragment>

    )
}

export default AppsPage