
import React, { Fragment } from 'react'
import { Route, Routes, } from 'react-router-dom'

import './App.css';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage'
import AppsPage from './pages/AppsPage';
import ContactPage from './pages/ContactPage'
import Footer from './components/Footer'
import DeleteAccountForm from './components/DeleteAccountForm'
import JessChatPolicy from './pages/JessChatPolicy';
import BarterPolicy from './pages/BarterPolicy';

function App() {
  

  return (
    <div className='App'>
      <NavBar />
      <Routes>
        <Route path='/' exact element={<HomePage />}/>
        <Route path='/profile' exact element={<ProfilePage />}/>
        <Route path='/apps' exact element={<AppsPage />}/>
        <Route path='/contact' exact element={<ContactPage />}/>
        <Route exact path="/jessChatPolicy" element={<JessChatPolicy/>} />
        <Route exact path="/barterPolicy" element={<BarterPolicy/>} />
        <Route exact path='/deleteAccount' element={<DeleteAccountForm />} /> 
        
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
//const navigate = useNavigate()
//  const reload = () => window.location.reload();
//<Route path="/jessChatPolicy">
//<Redirect push to={"/JessChatPolicy.html"}></Redirect>
//</Route>
