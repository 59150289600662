import React, { useState } from 'react'

import './ContactForm.css'

const formURL = "https://g4jjgxyczl.execute-api.us-east-1.amazonaws.com/live"

const ContactForm = () => {

    const [enteredEmail, setEnteredEmail] = useState('')
    const [enteredComment, setEnteredComment] = useState('')

    const onFormSubmitHandler = (event) => {
        event.preventDefault()
        console.log(`email got: ${enteredEmail}, comment got: ${enteredComment}`)
        const commentFormData = {
            "email": enteredEmail,
            "comment": enteredComment
        }
        sendToAWS(commentFormData)
        
    }

    async function sendToAWS(commentFormData) {
        try {
            const response = await fetch(formURL, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(commentFormData)
            });
            console.log("response" + response)
            
            const data = await response.json();
            if (data["success"] === "true") {
                alert("The comment was successfully submitted.  Thanks!")
            } 
            if (!response.ok) {
                console.log(response)
                //throw new Error("something wrong with post request")
            }
            console.log("data" + data)
            setEnteredEmail('')
            setEnteredComment('')
        } catch (error) {
            console.log("BBBBBBB" + error.message)
            //console.log(error)
            if (error.message.includes("ERR_FAILED 200")) {
                //alert("The comment was successfully submitted.  Thanks!")
            } else {
                //alert("There is error sending the comment.  Please try again later.")
            }
        } 
    }
        

    const emailChangeHandler = (event) => {
        setEnteredEmail(event.target.value)
    }

    const commentChangeHandler = (event) => {
        setEnteredComment(event.target.value)
    }

    return (
            <form onSubmit={onFormSubmitHandler}>
                <div className='contactForm-container'>
                        <label className='contact-comment-label-email'>Your email:</label>
                        <input className='contact-comment-inputBox-email' type='text' 
                            onChange={emailChangeHandler} value={enteredEmail}/>
                        <label className='contact-comment-label-comment'>Your comment:</label>
                        <input className='contact-comment-inputBox-textarea' type='text' 
                            onChange={commentChangeHandler} value={enteredComment}/>
                        <button className='contact-comment-submit-button' type='submit'>Submit</button>
                </div>
            </form>
                
    )
}

export default ContactForm