import React, { Fragment } from 'react'
import { NavLink, Link } from 'react-router-dom'

import './NavBar.css'
import jessie from '../images/bb_profile_04.JPG'


const NavBar = () => {
    return ( 
        <Fragment>
            <div className='nav-bar'>
                <div className='nav-title'>
                    <div className='nav-jess-name'>
                        Jessie Hon 
                        <div className='nav-jess-title'>
                        CPA, CGA
                    </div>
                    </div>

                    <img className='nav-jess-pic'
                        src={jessie} alt="Jessie Hon's picture" />
                </div>
                <div className='nav-menu'>
                    <Link className="nav-menu-home" exact='true' to='/'>Home</Link>
                    <Link className="nav-menu-profile" exact='true' to='/profile'>About Me</Link>
                    <Link className="nav-menu-apps" exact='true' to='/apps'>Apps</Link>
                    <Link className="nav-menu-contact" exact='true' to='/contact'>Contact</Link>
                </div>
            </div>
        </Fragment>
        
    )
}

export default NavBar
